import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo_footer.png";
import navIcon0 from '../assets/img/nav-icon0.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" title="Jaz Code It" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/jazbaliola/" target="_blank"><img src={navIcon0} alt="LinkedIn" /></a>
              <a href="https://github.com/jazbaliola" target="_blank"><img src={navIcon1} alt="Github" /></a>
              <a href="https://youtube.com" target="_blank"><img src={navIcon2} alt="Youtube" /></a>
              <a href="https://instagram.com/jazcodeit" target="_blank"><img src={navIcon3} alt="Instagram" /></a>
              <a href="https://facebook.com/jazcodeit" target="_blank"><img src={navIcon4} alt="Facebook" /></a>
            </div>
            <p>&copy; Jaz Code It {new Date().getFullYear()}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
