import React from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

/* Project Images */
import defaultImage from "../assets/img/projectImages/def.png";
import projectImg0 from "../assets/img/projectImages/0.png";
import quickshare from "../assets/img/projectImages/1.png";
import jtbot from "../assets/img/projectImages/jtbot.png";
import realtimeEditor from "../assets/img/projectImages/editor.png";
import thoughts from "../assets/img/projectImages/thoughts.png";
import memecloud from "../assets/img/projectImages/memecloud.png";
import jazbaliola from "../assets/img/projectImages/jazbaliola.png";
import covidescape from "../assets/img/projectImages/covidescape.png";
import cadses from "../assets/img/projectImages/cadses.png";
import cfsalberta from "../assets/img/projectImages/cfsalberta.png";
import prospectnow from "../assets/img/projectImages/prospectnow.png";
import imusic from "../assets/img/projectImages/logo512.png";

export const Projects = () => {

  const professionalProjects = [
    {
      title: "Alberta Computer for Schools",
      description: "Website for Alberta Computer for School Company.",
      codestack: "Html, CSS, Apache, Php, MySQL, Bootstrap",
      imgUrl: cfsalberta,
      projectLink: "https://cfsalberta.ca",
      projectYear: "2024",
      isBrokenLink: false,
    },
    {
      title: "Quick Serve",
      description: "Ticketing System & Booking System for Prospect Now Company.",
      codestack: "MySQL, Express JS, React JS, Node JS, Axios",
      imgUrl: prospectnow,
      projectLink: "https://prospectnow.ca/",
      projectYear: "2023",
      isBrokenLink: false,
    },
    {
      title: "Cadses",
      description: "Website for Company Cadsign Engineering Services.",
      codestack: "Html, CSS, JavaScript, Bootstrap",
      imgUrl: cadses,
      projectLink: "https://cadsign-engineering-services.web.app/",
      projectYear: "2021",
      isBrokenLink: false,
    },
  ];

  const personalProjects = [
    {
      title: "iMusic",
      description: "Listen to music with multiple selections of music available in the music library and you can upload your own music.",
      codestack: "React JS, Bootstrap, Php, MySQL, Axios, Apache",
      imgUrl: imusic,
      projectLink: "https://imusic.jazcodeit.ca",
      projectYear: "2024",
      isBrokenLink: true,
    },
    {
      title: "AIOLink App",
      description: "All in one link is your very own page on the internet. Customize, post images, link your other socials and receive anonymous messages.",
      codestack: "Html/CSS/Javascript, Bootstrap, Php, MySQL, Java, Swift, Apache",
      imgUrl: projectImg0,
      projectLink: "https://aiolink.app",
      projectYear: "2022",
      isBrokenLink: false,
    },
    {
      title: "Basic Weather App",
      description: "Weather app using Open Weather API and XMLHttpRequest and vanilla javascript.",
      codestack: "Html/CSS/Javascript",
      imgUrl: defaultImage,
      projectLink: "https://github.com/JazBaliola/BasicWeatherApp",
      projectYear: "2022",
      isBrokenLink: false,
    },
    {
      title: "Basic Calculator",
      description: "This personal project of mine is a Calculator that can do basic calculations. It is created using Java GUI.",
      codestack: "Java",
      imgUrl: defaultImage,
      projectLink: "https://github.com/JazBaliola/BasicCalculator",
      projectYear: "2022",
      isBrokenLink: false,
    },
    {
      title: "Covid Escape",
      description: "Covid Escape is a game that your character is a virus/covid-19 escaping from the vaccine and spreading all over the world.",
      codestack: "Python",
      imgUrl: covidescape,
      projectLink: "https://github.com/JazBaliola/CovidEscape",
      projectYear: "2021",
      isBrokenLink: false,
    },
    
    {
      title: "Thoughts",
      description: "Null",
      codestack: "Html/Css/Javascript, Bootstrap, Php, MySQL",
      imgUrl: thoughts,
      projectLink: "http://thoughts.rf.gd/",
      projectYear: "2020",
      isBrokenLink: true,
    },
    {
      title: "Meme Cloud",
      description: "Cloud storage for memes.",
      codestack: "Html/Css/Javascript, Bootstrap, Php, MySQL",
      imgUrl: memecloud,
      projectLink: "http://memecloud.rf.gd",
      projectYear: "2020",
      isBrokenLink: false,
    },
    {
      title: "Retro Arcade Theme Website",
      description: "Just my own website on a retro arcade theme.",
      codestack: "Html, Css, Javascript",
      imgUrl: jazbaliola,
      projectLink: "https://jaz-baliola.web.app/",
      projectYear: "2019",
      isBrokenLink: false,
    },
    {
      title: "Jaz Virtual Assistant",
      description: "Virtual Assistant",
      codestack: "Html/Css/Javascript, Bootstrap, Web Speech API, Firebase Authentication",
      imgUrl: defaultImage,
      projectLink: "https://jaz-tech.web.app/jazvirtualassistant",
      projectYear: "2019",
      isBrokenLink: false,
    },
    {
      title: "Realtime Code Editor",
      description: "Realtime Code Editor for HTML, CSS, JAVASCRIPT.",
      codestack: "Html/Css/Javascript, Bootstrap",
      imgUrl: realtimeEditor,
      projectLink: "https://jaz-tech.web.app/jteditor",
      projectYear: "2019",
      isBrokenLink: false,
    },
    {
      title: "Quick Share",
      description: "Quick Share is where you can upload files and quickly share it to other people with shareable link. Fast and Easy file sharing.",
      codestack: "Html/Css/Javascript, Bootstrap, Php, MySQL",
      imgUrl: quickshare,
      projectLink: "http://quickshare.rf.gd",
      projectYear: "2019",
      isBrokenLink: true,
    },
    {
      title: "JT Bot",
      description: "CLI Based, Personal Assistant with TTS.",
      codestack: "Batch Programming, VBScript",
      imgUrl: jtbot,
      projectLink: "https://jazcodeit.ca",
      projectYear: "2019",
      isBrokenLink: false,
    },
  ];

  const hardwareProjects = [
    {
      title: "Null",
      description: "Null",
      codestack: "CODESTACK",
      imgUrl: defaultImage,
      projectLink: "https://jazcodeit.ca",
      projectYear: "20XX",
      isBrokenLink: false,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>These are the Projects that I created. Some of them are from my Freelance work and some are Just my Personal Projects. I also created a tutorial on some of my projects, The tutorials can be found in my <a className="linkYT" href="https://youtube.com" target="_blank" title="Jaz Code It">Youtube Channel</a>. For more Projects visit my <a className="linkGIT" href="https://github.com/jazbaliola" target="_blank">Github</a>.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Professional Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Personal Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Hardware Projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          professionalProjects.map((projects, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...projects}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" >
                      <Row>
                        {
                          personalProjects.map((projects, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...projects}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        Nothing to see here. . . 👀
                        {/* {
                          hardwareProjects.map((projects, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...projects}
                                />
                            )
                          })
                        } */}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
