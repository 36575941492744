import { Container, Row, Col } from "react-bootstrap";
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import service0 from "../assets/img/service0.svg";
import service1 from "../assets/img/service1.svg";
import service2 from "../assets/img/service2.svg";
import service3 from "../assets/img/service3.svg";
import service4 from "../assets/img/service4.svg";
import service5 from "../assets/img/service5.svg";


export const Service = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="service" id="service">
      <Container>
        <Row className="align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Service</h2>
                        <p>I do freelance work, Here are some of my service I offer.<br></br> If you want to do business with me please kindly click the 'Email me' button to contact me.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={service0} alt="Icon" />
                                <h5>Static/Dynamic Website</h5>
                            </div>
                            <div className="item">
                                <img src={service1} alt="Icon" />
                                <h5>Database Design</h5>
                            </div>
                            <div className="item">
                                <img src={service2} alt="Icon" />
                                <h5>Java Application</h5>
                            </div>
                            <div className="item">
                                <img src={service3} alt="Icon" />
                                <h5>PC Build</h5>
                            </div>
                            <div className="item">
                                <img src={service4} alt="Icon" />
                                <h5>PC Repair</h5>
                            </div>
                            <div className="item">
                                <img src={service5} alt="Icon" />
                                <h5>Debug <b onClick={() => alert('I can debug (Java, Javascript/ES6, MySql, Oracle Sql, Html/Css, Php, React JS, Express JS)')}>Program's</b></h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        </Row>
      </Container>
    </section>
  )
}
