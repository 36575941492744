import React, { useEffect, useState } from 'react'
import {Navbar, Nav, Container} from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import navIcon0 from '../assets/img/nav-icon0.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';

export const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50){
                setScrolled(true);
            }else{
                setScrolled(false);
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    const onUpdateActiveLink = value => {
        setActiveLink(value);
    }

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="./">
            <img src={logo} alt="Logo" title="Jaz Code It" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"> 
            <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')}>Project</Nav.Link>
            <Nav.Link href="#service" className={activeLink === 'service' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('service')}>Service</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/jazbaliola/" target="_blank"><img src={navIcon0} alt="LinkedIn" /></a>
              <a href="https://github.com/jazbaliola" target="_blank"><img src={navIcon1} alt="Github" /></a>
              <a href="https://youtube.com" target="_blank"><img src={navIcon2} alt="Youtube" /></a>
              <a href="https://instagram.com/jazcodeit" target="_blank"><img src={navIcon3} alt="Instagram" /></a>
            </div>
            <button className="vvd" onClick={() => window.open("mailto:contact@jazcodeit.me?Subject=Lets%20Connect", '_self')}><span>Email me</span></button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
