import React from 'react';
import { Col } from "react-bootstrap";

import Swal from 'sweetalert2';

const brokenLink = () => {
  Swal.fire({
    title: "Broken Link",
    text: "Oops! This project link is broken due to issue in web hosting. Don't worry I will fix it soon.",
    icon: "warning",
    confirmButtonColor: "#30A0E0"
  });
}

const linkOpened = () => {
  Swal.fire({
    title: "Link opened in new tab",
    text: "The project that you wanted to see is on the other tab.",
    icon: "success",
    confirmButtonColor: "#30A0E0"
  });
}

/* Template for Project Card */
export const ProjectCard = ({ title, description, codestack , imgUrl, projectLink, projectYear, isBrokenLink }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <span><i>{projectYear}</i></span>
          <h4>{title}</h4>
          <span>{description}</span><br/>
          <a className="link-warning link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href={projectLink} onClick={isBrokenLink ? brokenLink : linkOpened} target='_blank'>View Project</a><br/>
          <span className='text-dark'>{codestack}</span>
        </div>
      </div>
    </Col>
  )
}
